body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0,0 ,0.87);
  text-align: left;
}

@tailwind base;
@tailwind components;
@tailwind utilities;